import Logo from './assets/starling-logo.png'
import LateNight from './assets/LNR.webp'
import batboy from './assets/batboy.png'

import React, { useState } from 'react'

function App() {
    const [openModal, setOpenModal] = useState(false)
    const [modalSource, setModalSource] = useState('')

    const toggleModal = (source) => {
        setOpenModal(!openModal)
        setModalSource(source)
    }

    return (
        <main className="relative lg:w-screen min-h-screen">
            <div className="2xl:grid grid-cols-3 gap-8 flex flex-col">
                <div className="col-span-1 flex flex-col justify-center items-center">
                    <img
                        src={Logo}
                        alt="Starling Performing Arts Logo"
                        className="lg:object-cover object-contain"
                    />
                </div>

                <div className="col-span-2 bg-gray-800/50 flex flex-col justify-center items-center p-10 mx-auto gold-shadow rounded-md">
                    <h1>Starling Performing Arts</h1>
                    <p>
                        Greetings and welcome to Starling Performing Arts,
                        formerly known as Ohlook Performing Arts. As we
                        diligently work on the develpment of our Starling
                        website, you have been redirected here to seamlessly
                        continue your engagement, whether it be signing up for
                        auditions, registering for camps and classes, or
                        securing tickets for our ongoing shows.
                    </p>
                    <p>
                        We appreciate your flexibility and patience during this
                        transitional period and look forward to delivering an
                        enhanced and exciting experience in the near future.
                        Thank you for your understanding and continued support.
                    </p>
                </div>
            </div>
            <div className="2xl:grid grid-cols-3 2xl:gap-8 flex flex-col mt-10 2xl:mt-0">
                <nav
                    id="buttons"
                    className="col-start-2 col-span-2 2xl:mt-36 my-20 m-auto flex flex-col md:flex-row justify-center 2xl:items-end lg:gap-8 gap-4 font-bitter"
                >
                    <a href="https://www.signupgenius.com/go/10C0948AFAC2DA1FFC43-46761327-spring#/">
                        Auditions
                    </a>
                    <a href="https://classbug.com/businesses/3181/offerings">
                        Classes
                    </a>
                    <a href="https://buy.ticketstothecity.com/venue.php?org_id=134">
                        Tickets
                    </a>

                    <a href="mailto:starlingperformingarts@gmail.com">
                        Contact Us
                    </a>
                </nav>
                {/* {openModal && (
                    <div
                        onClick={() => {
                            toggleModal()
                        }}
                        className="modal"
                    >
                        <div className="close-msg">
                            Click on the overlay to close
                        </div>

                        <div className="flex flex-col items-center modal-msg">
                            <h2>Performances of Batboy: The Musical</h2>
                            <h3>July 12-14, 19-21</h3>
                            <h3>Tickets go on sale starting in June</h3>
                        </div>
                    </div>
                )} */}
            </div>
            {/* <div className="2xl:absolute 2xl:w-2/6 w-1/2 lg:w-2/5 m-auto 2xl:h-2/8 2xl:bottom-0 2xl:left-0 glow">
                <img
                    src={LateNight}
                    alt="Moon with purple hue, bats flying across. The words Late Night Returns are lit up like a neon sign"
                    className="object-contain"
                    onClick={toggleModal}
                />
            </div> */}
        </main>
    )
}

export default App
